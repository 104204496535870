
// FROM HERE: https://at.org/tutorial/hackathon-boilerplate-project.html

import React, { Component,useState } from 'react';
import { Modal,Button } from 'react-bootstrap';
import './App.css';
import Gallery from "./Gallery";
import Banner from "./Banner";
import AllProjects from "./AllProjects";
import GalleryInfo from "./GalleryInfo";
import MyScum from "./MyScum";
import ArtistRoster from "./ArtistRoster";
import Dashboard from "./Dashboard";


import CurrentShow from "./CurrentShow";


// import { allReleasesInfo } from "./pageInfo/allReleasesInfo";
// import { imagesInfo } from "./pageInfo/imagesInfo";
// import { ipfsHashesUnforms } from "./pageInfo/ipfs_jsonHashes_ordered";
import { allJsonIpfsHashes } from "./pageInfo/ipfs_jsonHashes_ordered_rnd2";

import logo from '../scum.jpg';
import { FaListUl } from 'react-icons/fa';

// We'll use ethers to interact with the Ethereum network and our contract
import { ethers } from "ethers";

// We import the contract's artifacts and address here, as we are going to be
// using them with ethers
// import TokenArtifact from "../contracts/Token.json";
// import contractAddress from "../contracts/contract-address.json";
// import TokenArtifact_BabyGun from "../contracts/Nft_BabyGun.json";
import TokenArtifact_ScumblocksX from "../contracts/ScumBlocks.json";

import contractAddress from "../contracts/contract-address.json";

// All the logic of this dapp is contained in the Dapp component.
// These other components are just presentational ones: they don't have any
// logic. They just render HTML.
import { NoWalletDetected } from "./NoWalletDetected";
import { ConnectWallet } from "./ConnectWallet";
import { Loading } from "./Loading";
import { Transfer } from "./Transfer";
import { TransactionErrorMessage } from "./TransactionErrorMessage";
import { WaitingForTransactionMessage } from "./WaitingForTransactionMessage";


import { createBrowserHistory } from "history";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';


import {
  Router,
  Switch,
  Route,
  Link,
  withRouter,
  useHistory,
  NavLink,
  Redirect,
  useLocation
} from "react-router-dom";
const history = createBrowserHistory();

// This is the at Network id, you might change it in the at.config.js
// Here's a list of network ids https://docs.metamask.io/guide/ethereum-provider.html#properties
// to use when deploying to other networks.
// const AT_NETWORK_ID = '31337';

// local good
// const AT_NETWORK_ID = '1337';

// matic mumbia
const AT_NETWORK_ID = '80001';

const POLYGON_NETWORK_ID = '137';

// This is an error code that indicates that the user canceled a transaction
const ERROR_CODE_TX_REJECTED_BY_USER = 4001;


function BottomPart(props){
  return (
    <h2>Projects:</h2>
  )
}

const PROJECT_LOOKUP_TABLE = {
      unforms:"0",
      ninetynineartbiostoapdf:"1",
      babygun:"2"


    }
const CURRENT_RELEASE_INDEX = 0;
const WEI_IN_ETH = 1000000000000000000;





function ModalTest(props) {
  // const [show, setShow] = useState(props.isShown);

  const handleClose = () => {
    props.showModal(false,"","")
  }


  return (

      <Modal show={props.isShown} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.theError[0]}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.theError[1]}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
  );
}


export class Dapp extends React.Component {
  constructor(props) {
    super(props);

    // We store multiple things in Dapp's state.
    // You don't need to follow this pattern, but it's an useful example.
    this.initialState = {
      // The info of the token (i.e. It's Name and symbol)
      tokenData: undefined,
      // The user's address and balance
      selectedAddress: undefined,
      balance: undefined,
      // The ID about transactions being sent, and any possible error with them
      txBeingSent: undefined,
      transactionError: undefined,
      networkError: undefined,
      isLoading: false,
      imageLz: [],
      allReleasesInfo: {},
      primaryReleaseInfo: {},
      top: "ScumBlocks",
      bottom:"Immutable Art for All",
      featuredRelease:0,
      myCollection:[],
      isShown:false,
      currentContractError:[],
      primaryShow: "unforms"
    };

    this.state = this.initialState;
    this.updateInfo = this.updateInfo.bind(this);



  }


  // setBanner = (t,b) => {
  //   console.log("sett")
  //   this.setState({
  //     top:t,
  //     bottom:b
  //   });
  // }

  // componentDidMount() {
  //   this.setState({
  //     top: "ScumBlocks",
  //     bottom:"Immutable Art for All"
  //   });
  // }

  // Issues = () => {
  //   return (
  //     <div>
  //     {!this.state.selectedAddress ?
  //         <ConnectWallet
  //           connectWallet={() => this._connectWallet()}
  //           networkError={this.state.networkError}
  //           dismiss={() => this._dismissNetworkError()}
  //         /> :
  //         <React.Fragment></React.Fragment>
  //
  //     }
  //     {!this.state.tokenData
  //         ? <Loading />
  //         : <React.Fragment></React.Fragment>
  //       }
  //       </div>
  //   )
  // }

  addNetwork = async (id) => {
    let networkData;
    console.log("aaddddding")
    switch (id) {
      case 137:
        networkData = [
          {

            chainId: "0x89",
            chainName: "Polygon",
            rpcUrls: ["https://rpc-mainnet.matic.network"],
            nativeCurrency: {
              name:  "MATIC",
              symbol: "MATIC",
              decimals: 18,
            },
            blockExplorerUrls: ["https://polygonscan.com/"],
          },
        ];
        break;

      default:

        break;

    }

    // agregar red o cambiar red

    return window.ethereum.request({

      method: "wallet_addEthereumChain",

      params: networkData,

    });

  }



  render() {
    // const path = useLocation().pathname;
    // console.log("path"+path)
    // const location = useLocation();
    // Ethereum wallets inject the window.ethereum object. If it hasn't been
    // injected, we instruct the user to install MetaMask.
    // if (window.ethereum === undefined) {
    //   return <NoWalletDetected />;
    // }



    // The next thing we need to do, is to ask the user to connect their wallet.
    // When the wallet gets connected, we are going to save the users's address
    // in the component's state. So, if it hasn't been saved yet, we have
    // to show the ConnectWallet component.
    //
    // Note that we pass it a callback that is going to be called when the user
    // clicks a button. This callback just calls the _connectWallet method.
    // if (!this.state.selectedAddress) {
    //   return (
    //     <ConnectWallet
    //       connectWallet={() => this._connectWallet()}
    //       networkError={this.state.networkError}
    //       dismiss={() => this._dismissNetworkError()}
    //     />
    //   );
    // }
    //
    // // If the token data or the user's balance hasn't loaded yet, we show
    // // a loading component.
    // if (!this.state.tokenData) {
    //   return <Loading />;
    // }

    // If everything is loaded, we render the application.
    return (
      <Router history={history}>
      <div>
      <Navbar bg="dark" expand="md">
        <Link to="/"><Navbar.Brand >ScumBlocks Gallery</Navbar.Brand></Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav"> <FaListUl color="white"  /> </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto ">
              {/* this should maybe link to /featured and then featured should map to unforms primary ???*/}
            <NavLink id="guys" activeStyle={{ color: 'white' }} className="nav-link" to="/all/unforms">Featured Show</NavLink>
            <NavLink id="guys" activeStyle={{ color: 'white' }} className="nav-link" to="/all">All Shows</NavLink>
            <NavLink id="guys" activeStyle={{ color: 'white' }} className="nav-link" to="/my">My Collection</NavLink>
            <NavLink id="guys" activeStyle={{ color: 'white' }} className="nav-link" to="/aboutScumBlocks">Gallery Info</NavLink>
              <NavLink id="guys" activeStyle={{ color: 'white' }} className="nav-link" to="/artistRoster">Artist Roster</NavLink>

        {/*  <NavLink id="guys" activeStyle={{ color: 'white' }} className="nav-link" to="/createAuction">Create Auction</NavLink>*/}

          </Nav>
          <p onClick={e => this.handleRefresh(e)} className="refText">Reload Blockchain</p>
          <img onClick={e => this.handleRefresh(e)}  className="topRef" src={`/ref2.png`}  ></img>

          <div className="my-2 my-lg-0 nav-item">
           <small className="text-white my-2 my-sm-0"><span id="account"></span></small>
           </div>
        </Navbar.Collapse>
      </Navbar>
      </div>
              <div className="coca">
      <div className="container contPadding">

        {/*
          <div className="row">
            <div className="col-12">
        </div>
        </div>
        */}


        <Banner top="ScumBlocks" bottom="Immutable Art for All"/>
        {(this.state.txBeingSent || this.state.transactionError)
          ?
          <div className="row " >
            <div className="col margey wrapit">

              {/*
                          <p>poos</p>
                Sending a transaction isn't an immidiate action. You have to wait
                for it to be mined.
                If we are waiting for one, we show a message here.
              */}
              {this.state.txBeingSent && (
                <WaitingForTransactionMessage txHash={this.state.txBeingSent} />
              )}

              {/*
                Sending a transaction can fail in multiple ways.
                If that happened, we show a message here.
              */}
              {this.state.transactionError && (
                <TransactionErrorMessage
                  message={this._getRpcErrorMessage(this.state.transactionError)}
                  dismiss={() => this._dismissTransactionError()}
                />
              )}
            </div>
          </div>
          :<></>
        }

          {this.state.isShown &&
            <ModalTest showModal={this.showModal} isShown={this.state.isShown} theError={this.state.currentContractError} />
          }
          {window.ethereum === undefined
            ? <NoWalletDetected />
            : !this.state.selectedAddress
              ? <ConnectWallet
                    connectWallet={() => this._connectWallet()}
                    networkError={this.state.networkError}
                    dismiss={() => this._dismissNetworkError()}
                    getChain={this.addNetwork}
                  />
                : ( this.state.isLoading ? <Loading /> : (

            <Switch>

              <Route path="/all">
                <AllProjects isLoading = {this.state.isLoading} Loading={this.Loading} getAllMintedTokenUris={this.getAllMintedTokenUris} primaryReleaseInfo={this.state.primaryReleaseInfo} mint={this.mint} updateInfo={this.updateInfo} imageLz={this.state.imageLz} allReleasesInfo={this.state.allReleasesInfo}/>
              </Route>
              <Route path="/my">
                <MyScum  handleCollectionRefresh={this.handleCollectionRefresh} myCollection={this.state.myCollection} primaryReleaseInfo={this.state.primaryReleaseInfo} allReleasesInfo={this.state.allReleasesInfo}/>
              </Route>
              <Route path="/aboutScumBlocks">
                <GalleryInfo  primaryReleaseInfo={this.state.primaryReleaseInfo} allReleasesInfo={this.state.allReleasesInfo}/>
              </Route>
              <Route path="/artistRoster">
                <ArtistRoster  primaryReleaseInfo={this.state.primaryReleaseInfo} allReleasesInfo={this.state.allReleasesInfo}/>
              </Route>
              <Route path="/dashboard">
                <Dashboard  getBalance={this.getBalance} getTotalSalesAmountReceivedByProject={this.getTotalSalesAmountReceivedByProject} getCurrentAmountInProjectCoffers={this.getCurrentAmountInProjectCoffers} requestPayout={this.requestPayout} checkIfAdmin={this.checkIfAdmin} addProject={this.addProject} updateProject={this.updateProject} allReleasesInfo={this.state.allReleasesInfo}/>
              </Route>



              <Route path="/">

                <CurrentShow primaryShow={this.state.primaryShow} primaryReleaseInfo={this.state.primaryReleaseInfo} featuredRelease={this.state.featuredRelease} updateInfo={this.updateInfo} imageLz={this.state.imageLz} allReleasesInfo={this.state.allReleasesInfo}/>

              </Route>


              {/*  REDIRECT IS PROBLEM FOR GCP
              <Redirect from="/" to="/products" exact />
              <Redirect from="/" to="/all" exact />


              */}


            </Switch>
          ))}

      </div>
    </div>
      </Router>
    );
  }





  componentWillUnmount() {
    // We poll the user's balance, so we have to stop doing that when Dapp
    // gets unmounted
    this._stopPollingData();
  }

   addProject = async (fullTitle,artistAddress,price,maxMint,primaryReleaseProjectCode) => {
         const contract = this._NftContractScumblocks;
         await contract.addProject(fullTitle, artistAddress, ethers.utils.parseEther(String(price)), maxMint, "ipfs://",primaryReleaseProjectCode);

   }
   requestPayout = async (projectId) => {
         const contract = this._NftContractScumblocks;
         await contract.dispenseFundsByProject(projectId);

   }


   checkIfAdmin = async () => {
         const contract = this._NftContractScumblocks;

         // let white = await contract.isWhitelisted();
         // console.log(white);
         let addr = await contract.scumBlocksAddress();
         // console.log("scum add"+addr)
         // console.log("my window addrss"+this.state.selectedAddress)
         // console.log("are tey? "+ethers.utils.getAddress(addr) == ethers.utils.getAddress(this.state.selectedAddress))
         return (ethers.utils.getAddress(addr) == ethers.utils.getAddress(this.state.selectedAddress))


   }

   getCurrentAmountInProjectCoffers = async () => {
         const contract = this._NftContractScumblocks;
         let amountsByProj = [];
         let numberOfProjects = await contract.getNumberOfCurrentProjects();

         for (let i=0;i<numberOfProjects;i++){
           let amount = await contract.getCurrentAmountInProjectCoffers(i);
           amount =  ethers.utils.formatEther(amount, {commify: true});
           amountsByProj.push(amount);
         }
         return amountsByProj;

   }
   getTotalSalesAmountReceivedByProject = async () => {
         const contract = this._NftContractScumblocks;
         let amountsByProj = [];
         let numberOfProjects = await contract.getNumberOfCurrentProjects();
         // getTotalSalesAmountReceivedByProject
         for (let i=0;i<numberOfProjects;i++){
           let amount = await contract.getTotalSalesAmountReceivedByProject(i);
           amount =  ethers.utils.formatEther(amount, {commify: true});
           amountsByProj.push(amount);
         }
         return amountsByProj;

   }
   getBalance = async () => {
         const contract = this._NftContractScumblocks;
         let b = await contract.getBalance();
         b =  ethers.utils.formatEther(b, {commify: true})
         return b;
   }




   updateProject = async (category,id,value) => {
         const contract = this._NftContractScumblocks;
         console.log("update: "+category)
         switch(category) {
          case "DESCRIPTION":
            await contract.updateProjectDescription(id,value);
            break;
          case "ARTIST_NAME":
            await contract.updateProjectArtistName(id,value);
            break;
          case "PROJECT_WEBSITE":
            await contract.updateProjectWebsite(id, value);
            break;
          case "MINTING_POLICY":
            await contract.updateMintingPolicyByProject(id,value);
            break;
          case "PRICE":
            await contract.updateProjectPricePerTokenInWei(id,ethers.utils.parseEther(String(value)));
            break;
          case "MAX_INVOCATIONS":
            await contract.updateProjectMaxInvocations(id,value);
            break;
          case "IS_ACTIVE":
            await contract.toggleProjectIsActive(id);
            break;
          case "IS_PAUSED":
            await contract.toggleProjectIsPaused(id);
            break;
          case "IS_IGNORED":
            await contract.toggleProjectIsIgnored(id);
            break;
          default:
            break;
        }

   }







   mint = async (primaryReleaseProjectCode, tokenUri,artistAddress,namedWebId,price,extra,imageListProjectCode) => {
    console.log("asdf");
    // PROJECT_LOOKUP_TABLE[projectName]
    const projectId =  parseInt(PROJECT_LOOKUP_TABLE[primaryReleaseProjectCode.toLowerCase()]);
    // console.log("minter pojID: "+projectId);
    const c = this._NftContractScumblocks;
    // console.log("uri json: "+tokenUri);

    if (imageListProjectCode==primaryReleaseProjectCode){
      try{
        let tx = await c.mint(this.state.selectedAddress,tokenUri,projectId,imageListProjectCode,artistAddress,parseInt(namedWebId),extra, { value: ethers.utils.parseEther(price) });
        console.log("minted"+tx);
        this.setState({ txBeingSent: tx.hash });
        // We use .wait() to wait for the transaction to be mined. This method
        // returns the transaction's receipt.
        const receipt = await tx.wait();
        console.log("recipt"+receipt)
        this.handleMintRefresh()
        // The receipt, contains a status flag, which is 0 to indicate an error.
        if (receipt.status === 0) {
          // We can't know the exact error that made the transaction fail when it
          // was mined, so we throw this generic one.
          throw new Error("Transaction failed");
        }

        // If we got here, the transaction was successful, so you may want to
        // update your state. Here, we update the user's balance.
        // await this._updateBalance();
      } catch (error) {
        // We check the error code to see if this error was produced because the
        // user rejected a tx. If that's the case, we do nothing.
        if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
          return;
        }

        // Other errors are logged and stored in the Dapp's state. This is used to
        // show them to the user, and for debugging.
        console.error(error);
        this.setState({ transactionError: error });
      } finally {
        // If we leave the try/catch, we aren't sending a tx anymore, so we clear
        // this part of the state.
        this.setState({ txBeingSent: undefined });
      }
    } else {
      console.log("failed to mint, wrongproject: "+imageListProjectCode +"  "+primaryReleaseProjectCode);
    }

    //DO CHECK HERE to make sure id and jsonHash are same from json file
    // if (imageListProjectCode==primaryReleaseProjectCode){
    //   try{
    //     let res = await c.mint(this.state.selectedAddress,tokenUri,projectId,imageListProjectCode,artistAddress,parseInt(namedWebId),extra, { value: ethers.utils.parseEther(price) });
    //     console.log("minted"+res);
    //     this.showModal(true,"Submitted","Mint submitted to blockchain. Currently pending confirmation. Once your item has minted, be sure to click the 'Reload Blockchain' button at the top right.")
    //   } catch(e){
    //     console.log("error"+ e)
    //     // this.showModal(true,e.reason)
    //     if (e.hasOwnProperty("reason")) {
    //         this.showModal(true,"Error",e.reason)
    //     }
    //     else if (e.hasOwnProperty("data")) {
    //       this.showModal(true,"Error",e.data.message)
    //
    //     }
    //     else {
    //         this.showModal(true,"Error","Unknown Error")
    //     }
    //   }
    // } else {
    //   console.log("failed to mint: "+imageListProjectCode +"  "+primaryReleaseProjectCode);
    // }
  }

  showModal = (isShown,topMessage, error) => {
    this.setState({
      isShown:isShown,
      currentContractError:[topMessage,error]
    })
  }

  handleRefresh = async (e) => {
    // let curr = this.state.featuredRelease;
    console.log('refresh'+e);
    // this._connectWallet();
    if (this.state.tokenData){
      this._stopPollingData();
      this._partialResetState();
      // this.setState({featuredRelease:curr})
      this._getTokenData(this.state.featuredRelease);
      this._setReleasePageInfoFromJson();
      // this._getMyCollection();
      // this._startPollingData();
    }

  }
  handleCollectionRefresh = async (e) => {
    // let curr = this.state.featuredRelease;
    console.log('refresh'+e);
    // this._connectWallet();
    if (this.state.tokenData){
      this._getMyCollection();
    }

  }
  handleMintRefresh = async () => {
    // let curr = this.state.featuredRelease;
    console.log('refresh');
    // this._connectWallet();
    if (this.state.tokenData){
      this._stopPollingData();
      this._partialResetState();
      // this.setState({featuredRelease:curr})
      this._getTokenData(this.state.featuredRelease);
      // this._setReleasePageInfoFromJson();
      // this._getMyCollection();
      // this._startPollingData();
    }

  }

  _connectWallet = async () => {
    // This method is run when the user clicks the Connect. It connects the
    // dapp to the user's wallet, and initializes it.

    // To connect to the user's wallet, we have to run this method.
    // It returns a promise that will resolve to the user's address.
    const [selectedAddress] = await window.ethereum.enable();

    // Once we have the address, we can initialize the application.

    // First we check the network
    if (!this._checkNetwork()) {
      return;
    }

    await this._initialize(selectedAddress);

    // We reinitialize it whenever the user changes their account.
    window.ethereum.on("accountsChanged", ([newAddress]) => {
      console.log("Zzzzz")
      // this._stopPollingData();
      // `accountsChanged` event can be triggered with an undefined newAddress.
      // This happens when the user removes the Dapp from the "Connected
      // list of sites allowed access to your addresses" (Metamask > Settings > Connections)
      // To avoid errors, we reset the dapp state
      if (newAddress === undefined) {
        return this._resetState();
      }

      this._initialize(newAddress);
    });

    // We reset the dapp state if the network is changed
    window.ethereum.on("chainChanged", ([networkId]) => {
      console.log("network changed")
      // this._stopPollingData();
      this._resetState();
    });
  }

  async _initialize(userAddress) {
    // This method initializes the dapp

    // We first store the user's address in the component's state
    this.setState({
      selectedAddress: userAddress,
    });

    // Then, we initialize ethers, fetch the token's data, and start polling
    // for the user's balance.

    // Fetching the token data and the user's balance are specific to this
    // sample project, but you can reuse the same initialization pattern.
    this._intializeEthers();
    //TODO: make this get token contingent on what's clicked
    // ALSO  make the main NEW show on front page ALWAYS download

    //THIS should be most recent proejct
    //Defaults to zero now
    console.log("about to get token data from dapp")
    let numberOfProjects = await this._setReleasePageInfoFromJson();
    if (numberOfProjects>0){
      this._getTokenData(this.state.featuredRelease);
    }
    // this._getMyCollection();

    // this._startPollingData();
    // this.getAllMintedTokenUris("unforms");
    // console.log("qqq"+nn)
  }

  _getMyCollection = async () => {
    const c = this._NftContractScumblocks;
    let myColl = [];
    let bal = await c.balanceOf(this.state.selectedAddress);
    console.log("balance of: "+bal);

    // let tokens = await c.tokenOfOwnerByIndex(user, index)
    // for index = 0, 1, 2, …, balanceOf(user) - 1.

    for(var i = 0; i < bal.toNumber(); i++) {
        try {
          let tokenId = await c.tokenOfOwnerByIndex(this.state.selectedAddress, i);
          console.log("tokeId"+tokenId)
          let projId = await c.tokenIdToProjectId(tokenId);
          let projectDetails = await c.projectDetails(projId);
          let fullIpfsLink = await c.tokenURI(tokenId);

          let ipfsLink = fullIpfsLink;
          try {
            ipfsLink = ipfsLink.split("://")[1];
          }catch(e){
            console.log("eeeeeerrrrr")
          }
          console.log("ipfsll "+ipfsLink)
          console.log(typeof allJsonIpfsHashes[projId])
          // console.log(allJsonIpfsHashes[projId].some(item => item.jsonIpfsHash === 'fullIpfsLink'));
          let actualImgHash ="";
          let title = "";
          let supply = Object.keys(allJsonIpfsHashes[projId]).length;
          if (supply>0){
            for (let a=0;a<supply;a++){
              if (allJsonIpfsHashes[projId][a+1].jsonIpfsHash == ipfsLink){
                console.log("found it")
                actualImgHash = allJsonIpfsHashes[projId][a+1].imageIpfsHash
                title = allJsonIpfsHashes[projId][a+1].name

              }
            }
          }

          let imgUri =  process.env.PUBLIC_URL + "/fullOut/"+projectDetails[6]+"/"+actualImgHash+".jpg";

          let eachOne = {
            projectName: projectDetails[0],
            artistName: projectDetails[1],
            projectCode:projectDetails[6],
            projectId:projId,
            ipfsLink: ipfsLink,
            imgUri:imgUri,
            title:title
          }
          myColl.push(eachOne);
        }catch(e){
          console.log("error getting collection"+e)
        }
      }

      this.setState({
        myCollection:myColl
      })

  }

  _setReleasePageInfoFromJson = async () => {
    const c = this._NftContractScumblocks;
    let allReleasesInfo = {};
    let projectCodeArr = [];
    let numberOfProjects = await c.getNumberOfCurrentProjects();
    for (let i=0;i<numberOfProjects;i++){
      try{

        let projectTokenInfo = await c.projectTokenInfo(i)
        let projectDetails = await c.projectDetails(i)

        // check if IS_IGNORED
        if (projectDetails[7] == false){
          // console.log("sutff:"+projectDetails);
          // console.log("sutff:"+projectTokenInfo);
          let code = projectDetails[6];
          // console.log("projcode: "+code);
          allReleasesInfo[code]= {
            projectName: projectDetails[0],
            artistName: projectDetails[1],
            projectCode:projectDetails[6],
            priceInEth: ethers.utils.formatEther(projectTokenInfo[1]),
            minted:parseInt(projectTokenInfo[2]),
            maxMint:parseInt(projectTokenInfo[3]),
            activeBool:projectTokenInfo[4],
            projectId:i,
            pausedBool:projectDetails[8],
            description: projectDetails[2],
            license: projectDetails[4],
            mintingPolicy: projectDetails[5]


          }
          projectCodeArr.push(projectDetails[6]);
        }
      } catch(e){
        console.log('exception in setRelease')
      }
    }
    allReleasesInfo["projectCodeArr"]=projectCodeArr;
    console.log("proj code arr"+projectCodeArr)

    this.setState({allReleasesInfo:allReleasesInfo})

    return numberOfProjects;
  }




  async _intializeEthers() {
    // We first initialize ethers by creating a provider using window.ethereum
    this._provider = new ethers.providers.Web3Provider(window.ethereum);

    // When, we initialize the contract using that provider and the token's
    // artifact. You can do this same thing with your contracts.
    // this._NftContract = new ethers.Contract(
    //   contractAddress.Token,
    //   TokenArtifact.abi,
    //   this._provider.getSigner(0)
    // );
    console.log("adsf_PRE")

    // const MyContract = await ethers.getContractFactory("Nft");
    // this._NftContract = await MyContract.attach(
    //   "0x5FbDB2315678afecb367f032d93F642f64180aa3" // The deployed contract address
    // );
    console.log("adsf")
    // console.log(_NftContract)

    this._NftContractScumblocks = new ethers.Contract(
      contractAddress.ScumBlocks,
      TokenArtifact_ScumblocksX.abi,
      this._provider.getSigner(0)
    );
    console.log("contracdtAddress.SCumblcoks,+"+contractAddress.ScumBlocks)


  }


  _startPollingData() {
    // this._pollDataInterval = setInterval(() => this._updateBalance(), 1000);

    // We run it once immediately so we don't have to wait for it
    // this._updateBalance();
  }

  _stopPollingData() {
    // clearInterval(this._pollDataInterval);
    // this._pollDataInterval = undefined;
  }

  async updateInfo(_projectName){
    let projectName = _projectName.toLowerCase();

    console.log("update");
    console.log("PROJECT_LOOKUP_TABLE[projectName] "+PROJECT_LOOKUP_TABLE[projectName]);
    if (PROJECT_LOOKUP_TABLE[projectName]){

      let projectId = parseInt(PROJECT_LOOKUP_TABLE[projectName]);
      this.setState({
        featuredRelease: projectId
      });
      console.log("id: "+projectId + "actual: "+PROJECT_LOOKUP_TABLE[projectName]);
      await this._getTokenData(projectId);
  } else{
    history.push('/all')
  }

  }


  // The next two methods just read from the contract and store the results
  // in the component state.
  async _getTokenData(projectId) {
    console.log("get id: "+projectId)
    const c = this._NftContractScumblocks;
    if (await c.getNumberOfCurrentProjects()>0){
      this._partialResetState();
      this.setState({
        isLoading:true
      })


      // console.log(contractSent)
      const name = await c.name();
      const symbol = await c.symbol();
      console.log("Symbol of contract:"+symbol)
      this.setState({ tokenData: { name, symbol } });

      let j = await c.totalSupply()
      console.log("sutff:"+j);

      let projectDetails = await c.projectDetails(projectId)
      // console.log("sutff:"+projectDetails);

      // function projectTokenInfo(uint256 _projectId) view public returns (address artistAddress, uint256 pricePerTokenInWei, uint256 invocations, uint256 maxInvocations, bool active, string memory currency, address currencyAddress) {
      let projectTokenInfo = await c.projectTokenInfo(projectId)
      // console.log("sutff:"+projectTokenInfo);

      let mintedUris = await c.projectShowAllTokenUris(projectId);
      let webIds = await c.projectGetAllArtworkIdsOfMintedTokens(projectId);
      console.log('allids: '+webIds);
      webIds = webIds.map(Number);
      let rel = {
        projectName: projectDetails[0],
        artistName: projectDetails[1],
        description: projectDetails[2],
        license: projectDetails[4],
        mintingPolicy: projectDetails[5],
        projectCode: projectDetails[6],
        // longDescription: projectDetails[7],
        artistAddress:projectTokenInfo[0],
        priceInEth: ethers.utils.formatEther(projectTokenInfo[1]),
        minted:parseInt(projectTokenInfo[2]),
        maxMint:parseInt(projectTokenInfo[3]),
        activeBool:projectTokenInfo[4],
        webIds:webIds,
        mintedUris:mintedUris

      }
      this.setState({ primaryReleaseInfo: rel });
      console.log("finisehd loading primary realease")
      // if zero wilt throw error
      let supply = 0
      try{

        // ipfsHashesUnforms.forEach((item) => {
        //   console.log("ittttem"+item)
        //   // Object.entries(item).forEach(([key, val]) => {
        //   //   console.log(`key-${key}-val-${JSON.stringify(val)}`)
        //   // });
        // });
        supply = Object.keys(allJsonIpfsHashes[projectId]).length;
        //could use name of contractSent here?
        // supply = imagesInfo["unforms"].length
        console.log("suppddlu:"+supply)
      } catch (error){
        console.log(error)
      }
      let arr = [];
      this.setState({ imageLz: [] });
      if (supply>0){
        for (let a=0;a<supply;a++){
          // console.log("Getting from json #: "+a)

          let eachEntry = allJsonIpfsHashes[projectId][a+1]
          let justHashOfImg = eachEntry["imageIpfsHash"]
          // numberFromUnforms = ('0000'+numberFromUnforms).slice(-4);
          // console.log("numberUnforms:"+numberFromUnforms)
          let img =  process.env.PUBLIC_URL + "/fullOut/"+eachEntry["project_code_name"]+"/"+justHashOfImg+".jpg";
          let supplemental = "";
          if ("supplemental" in eachEntry){
            supplemental = process.env.PUBLIC_URL + "/supplemental/"+eachEntry["supplemental"];
          }
          let shortName =  eachEntry["name"];
          if ("shortName" in eachEntry){
            shortName = eachEntry["shortName"]
          }
          arr = arr.concat({
            artwork_id:eachEntry["artwork_id"],
            supplementalItem: supplemental,
            img:img,
            imageShortTitle: shortName,
            imageTitle:eachEntry["name"],
            jsonHashIpfs:eachEntry["jsonIpfsHash"],
            justHashOfImg:justHashOfImg,
            projectCode: eachEntry["project_code_name"]
          })
        }

        this.setState({ imageLz: arr });
      }

      this.setState({
        isLoading:false
      })
    }

  }

  // async _updateBalance() {
  //   const balance = await this._NftContractScumblocks.balanceOf(this.state.selectedAddress);
  //   this.setState({ balance });
  // }

  // This method sends an ethereum transaction to transfer tokens.
  // While this action is specific to this application, it illustrates how to
  // send a transaction.
  async _transferTokens(to, amount) {
    console.log(this.state.tokenData)
    // Sending a transaction is a complex operation:
    //   - The user can reject it
    //   - It can fail before reaching the ethereum network (i.e. if the user
    //     doesn't have ETH for paying for the tx's gas)
    //   - It has to be mined, so it isn't immediately confirmed.
    //     Note that some testing networks, like at Network, do mine
    //     transactions immediately, but your dapp should be prepared for
    //     other networks.
    //   - It can fail once mined.
    //
    // This method handles all of those things, so keep reading to learn how to
    // do it.

    try {
      // If a transaction fails, we save that error in the component's state.
      // We only save one such error, so before sending a second transaction, we
      // clear it.
      this._dismissTransactionError();

      // We send the transaction, and save its hash in the Dapp's state. This
      // way we can indicate that we are waiting for it to be mined.
      // const tx = await this._NftContract.transfer(to, amount);
      // this.setState({ txBeingSent: tx.hash });
      console.log("Sd amount:"+amount)
      // console.log(this._NftContract)
      console.log(this.state.selectedAddress)
      const tx = await this._NftContract.mint(this.state.selectedAddress, amount);
      this.setState({ txBeingSent: tx.hash });

      // We use .wait() to wait for the transaction to be mined. This method
      // returns the transaction's receipt.
      const receipt = await tx.wait();

      // The receipt, contains a status flag, which is 0 to indicate an error.
      if (receipt.status === 0) {
        // We can't know the exact error that made the transaction fail when it
        // was mined, so we throw this generic one.
        throw new Error("Transaction failed");
      }

      // If we got here, the transaction was successful, so you may want to
      // update your state. Here, we update the user's balance.
      // await this._updateBalance();
    } catch (error) {
      // We check the error code to see if this error was produced because the
      // user rejected a tx. If that's the case, we do nothing.
      if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
        return;
      }

      // Other errors are logged and stored in the Dapp's state. This is used to
      // show them to the user, and for debugging.
      console.error(error);
      this.setState({ transactionError: error });
    } finally {
      // If we leave the try/catch, we aren't sending a tx anymore, so we clear
      // this part of the state.
      this.setState({ txBeingSent: undefined });
    }
  }

  // This method just clears part of the state.
  _dismissTransactionError() {
    this.setState({ transactionError: undefined });
  }

  // This method just clears part of the state.
  _dismissNetworkError() {
    this.setState({ networkError: undefined });
  }

  // This is an utility method that turns an RPC error into a human readable
  // message.
  _getRpcErrorMessage(error) {
    if (error.data) {
      return error.data.message;
    }

    return error.message;
  }

  // This method resets the state
  _resetState() {
    this.setState(this.initialState);
  }

  _partialResetState(){
    this.setState({
      imageLz: [],
      primaryReleaseInfo: {}
    });
  }

  // This method checks if Metamask selected network is Localhost:8545
  _checkNetwork() {
    console.log(window.ethereum.networkVersion)
    if (window.ethereum.networkVersion === POLYGON_NETWORK_ID) {
      console.log("good")
      return true;
    }

    this.setState({
      networkError: 'Can\'t connect. Make sure you are connected to Polygon.'
    });

    return false;
  }

}
