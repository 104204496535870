import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

// allReleasesInfo[code]= {
//   projectName: projectDetails[0],
//   artistName: projectDetails[1],
//   projectCode:projectDetails[6],
//   priceInEth: ethers.utils.formatEther(projectTokenInfo[1]),
//   minted:parseInt(projectTokenInfo[2]),
//   maxMint:parseInt(projectTokenInfo[3]),
//   activeBool:projectTokenInfo[4],
//   projectId:i
//
// }




// {props.allReleasesInfo["projectCodeArr"] && props.allReleasesInfo["projectCodeArr"].map((item)=>{
//   return (
//     <p>{props.allReleasesInfo[item].projectName}</p>
//   )
// }
//
// )
//
// }



// export function Dashboard(props) {
const Dashboard = (props) => {
  const bal = async () => {
    setBalance(await props.getBalance())
    setBalanceByProject(await props.getCurrentAmountInProjectCoffers())
    setCumulativeBalanceByProject(await props.getTotalSalesAmountReceivedByProject())
    setIsWhitelisted(await props.checkIfAdmin())


  }
  useEffect(() => {
    console.log("dash");
    bal();

  }, []);

  const [balance, setBalance] = useState(0);
  const [balanceByProject, setBalanceByProject] = useState([]);
  const [cumulativeBalanceByProject, setCumulativeBalanceByProject] = useState([]);
  const [isWhitelisted, setIsWhitelisted] = useState(false);


  const [rowz, setRowz] = useState([]);
  const classes = useStyles();

  const compo =(val)=>{
    return(
      <div style={{width:"250px",float:"left",margin:"20px"}}>
      <h4>Update {val}</h4>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          const formData = new FormData(event.target);
          const projectId = formData.get("projectId");
          const description = formData.get("description");
          const category = "DESCRIPTION"
          props.updateProject(val,projectId,description)
        }}
      >
        <div className="form-group">
          <label>Project Id:</label>
          <input className="form-control" name="projectId" required />
        </div>
        <div className="form-group">
          <label>{val}:</label>
          <input className="form-control" name="description" required />
        </div>

        <div className="form-group">
          <input className="btn btn-primary" type="submit" value="Update" />
        </div>
      </form>
      </div>
    )
  }
  const customColumnStyle = { width: 120, backgroundColor: "green" };
  console.log("iswhite?"+isWhitelisted)
  //TODO CHECK TO MAKE SURE IS ADMIN BEFORE RENDERING
  if (!isWhitelisted){
    return (
      <h4  className="infoText">Blocked</h4>
    )

  }
  else{
  return (
    <div>
    <div className="topProj" >
    <h4 className="infoText">Current Projects</h4>
      {props.allReleasesInfo["projectCodeArr"]
       && <TableContainer component={Paper}>
        <Table className={classes.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Proj Title</TableCell>
              <TableCell align="left">Artist Name</TableCell>
              <TableCell align="left">Price</TableCell>
              <TableCell align="left">Minted</TableCell>
              <TableCell align="left">MaxMint</TableCell>
              <TableCell align="left">Proj ID #</TableCell>
              <TableCell align="left">ProjCode</TableCell>
              <TableCell align="left">Description</TableCell>
              <TableCell align="left">mintingPolicy</TableCell>
              <TableCell align="left">IsActive</TableCell>
              <TableCell align="left">IsPaused</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {props.allReleasesInfo["projectCodeArr"].map((row) => (
              <TableRow key={props.allReleasesInfo[row].projectCode}>
                <TableCell component="th" scope="row">
                  {props.allReleasesInfo[row].projectName}
                </TableCell>
                <TableCell align="left">{props.allReleasesInfo[row].artistName}</TableCell>
                <TableCell align="left">{props.allReleasesInfo[row].priceInEth}</TableCell>
                <TableCell align="left">{props.allReleasesInfo[row].minted}</TableCell>
                <TableCell align="left">{props.allReleasesInfo[row].maxMint}</TableCell>
                <TableCell align="left">{props.allReleasesInfo[row].projectId}</TableCell>
                <TableCell align="left">{props.allReleasesInfo[row].projectCode}</TableCell>
                <TableCell align="left">{props.allReleasesInfo[row].description ? "Desc is Here" :"Nothing here"}</TableCell>
                <TableCell left="right">{props.allReleasesInfo[row].mintingPolicy} _______________________________ </TableCell>
                <TableCell align="left">{props.allReleasesInfo[row].activeBool ? "active" : "not active"}</TableCell>
                <TableCell align="left">{props.allReleasesInfo[row].pausedBool ? "paused" : "not paused"}</TableCell>



              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    }
    </div>

      <div className="infoText" style={{width:"600px", margin:"auto"}}>
      <hr className="withPadding"/>
      <h4>Add Project</h4>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          const formData = new FormData(event.target);
          const fullTitle = formData.get("fullTitle");
          const artistAddress = formData.get("artistAddress");
          const price = formData.get("price");
          const maxMint = formData.get("maxMint");
          const primaryReleaseProjectCode = formData.get("primaryReleaseProjectCode");
          props.addProject(fullTitle,artistAddress,price,maxMint,primaryReleaseProjectCode)
        }}
      >
        <div className="form-group">
          <label>fullTitle:</label>
          <input className="form-control" name="fullTitle" required />
        </div>
        <div className="form-group">
          <label>artistAddress:</label>
          <input className="form-control" type="text" name="artistAddress" required />
        </div>
        <div className="form-group">
          <label>price:</label>
          <input className="form-control" type="text" name="price" required />
        </div>
        <div className="form-group">
          <label>MaxMint:</label>
          <input className="form-control" type="text" name="maxMint" required />
        </div>
        <div className="form-group">
          <label>Project Code:</label>
          <input className="form-control" type="text" name="primaryReleaseProjectCode" required />
        </div>

        <div className="form-group">
          <input className="btn btn-primary" type="submit" value="Add Project" />
        </div>
      </form>
      </div>


      <div>
      <hr className="withPadding" style={{clear: "both"}}/>

        {compo("DESCRIPTION")}
        {compo("ARTIST_NAME")}
        {compo("PROJECT_WEBSITE")}
        {compo("MINTING_POLICY")}
        {compo("MAX_INVOCATIONS")}
        {compo("PRICE")}
        {compo("IS_ACTIVE")}
        {compo("IS_PAUSED")}
        {compo("IS_IGNORED")}

      </div>
      <hr className="withPadding" style={{margin:"auto",clear: "both"}}/>
      <div className="infoText cccc" style={{width:"350px",margin:"auto",clear: "both"}}>


      <h3>PAYOUT STUFF</h3>
      <p>Current Balance: {balance} Matic</p>
      <p className="justBold">Balance By Project: </p>
      {balanceByProject.map((item, index) =>{
        return(
          <p>Project #{index}: {item} Matic</p>
        )
      })}

      <p className="justBold">Cumulative Balance By Project From Start: </p>
      {cumulativeBalanceByProject.map((item, index) =>{
        return(
          <p>Project #{index}: {item} Matic</p>
        )
      })}
    {/*
    */}
      <p> Money currently in each proejct now and maybe also how much has been there total since start</p>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          const formData = new FormData(event.target);
          const projectId = formData.get("projectId");
          props.requestPayout(projectId)
        }}
      >
        <div className="form-group">
          <label>Project Id:</label>
          <input className="form-control" name="projectId" required />
        </div>
        <div className="form-group">
          <input className="btn btn-primary" type="submit" value="Update" />
        </div>
      </form>
      </div>

      </div>
  );
  }
}
// await contract.updateProjectArtistName(id,"Hung/Horse");
// await contract.updateProjectWebsite(id,"www.scumblocks.com/all/ninetynineartbiostoapdf");
// await contract.updateMintingPolicyByProject(id,"There will be a total of 10 pieces minted.");
// await contract.toggleProjectIsActive(id);
// await contract.toggleProjectIsPaused(id);
// case "DESCRIPTION":
//   await contract.updateProjectDescription(id,value);
//   break;
// case "ARTIST_NAME":
//   await contract.updateProjectArtistName(id,value);
//   break;
// case "PROJECT_WEBSITE":
//   await contract.updateProjectWebsite(id, value);
//   break;
// case "MINTING_POLICY":
//   await contract.updateMintingPolicyByProject(id,value);
//   break;
// case "IS_ACTIVE":
//   await contract.toggleProjectIsActive(id);
//   break;
// case "IS_PAUSED":
//   await contract.toggleProjectIsPaused(id);
//   break;
// case "IS_IGNORED":
export default Dashboard;
