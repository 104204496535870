import React, { useState, useEffect } from 'react';
import {Link} from "react-router-dom";
import Carousel from 'react-bootstrap/Carousel'

import {Container, Row,Col } from 'react-bootstrap';

const CurrentShow = (props) => {
useEffect(() => {
  if (props.primaryReleaseInfo.projectCode !== props.primaryShow){
    console.log("sssss")
    props.updateInfo(props.primaryShow);
  }
  setCounter(Math.floor(Math.random() * props.imageLz.length))
}, []);

  const [counter, setCounter] = useState(1);
  const [arrLen, setArrLen] = useState(0);

  useEffect(() => {

    // if (props.imageLz.length) {
    //   setCounter(Math.floor(Math.random() * props.imageLz.length))
    // }
    // console.log("len"+props.imageLz.length)

  }, []);

  const [showAllText, setShowAllText] = useState(false);

  const NewlineText = (props) => {
    const text = !showAllText ? props.text.split("\r") : props.text.split("\r")[0];

    return <p className="nu">{text}</p>;
  }
  const releaseInfoTop = (showAll) =>{
    return(
      <div className="currentSHow">
    </div>
    )
  }
  // <h1>Current Show: Unforms by Hung/Horse</h1>

  // {props.imageLz.map((item) => (
  //     <ImageListItem key={item.number}>
  //       <img
  //         srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format 1x,
  //             ${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
  //         alt={item.name}
  //         loading="lazy"
  //       />
  //     <Link to={`${url}/${item.number}`}>
  //       {<ImageListItemBar position="bottom" title={item.name} />}
  //         </Link>
  //     </ImageListItem>
  //
  // ))}

  // const goo = Math.floor(Math.random() * props.imageLz.length)

  const bottom = (showAll) => (
    <div>
    {props.imageLz.length && (

      <div>

    {showAll &&
      <div className="currWhole">
        <div className="imgCurr">
        <ControlledCarousel imageLz={props.imageLz} goo={counter} >
        </ControlledCarousel>
        </div>
        <hr className="half-width" />
        <h2 className="curr">FEATURED SHOW:</h2>
        <h2>{props.primaryReleaseInfo.projectName}</h2>
        <hr className="half-width"/>
        <p className="justBold">by {props.primaryReleaseInfo.artistName}</p>
          {props.primaryReleaseInfo.activeBool
            ? <React.Fragment>
            <p><span className="boldIt">Minted: </span>{props.primaryReleaseInfo.minted} out of {props.primaryReleaseInfo.maxMint} ({props.primaryReleaseInfo.maxMint-props.primaryReleaseInfo.minted} Mints Remaining)</p>
            <p><span className="boldIt">Mint Price: </span>{props.primaryReleaseInfo.priceInEth} Matic </p>
            </React.Fragment>
            : <p className="ital"><span>(Project not active)</span></p>
          }
        <NewlineText  text={props.primaryReleaseInfo.description}/>

        </div>
      }



    </div>
    )}
  </div>
  )

  return (
    <React.Fragment>
    {releaseInfoTop(true)}

    {bottom(true)}
    </React.Fragment>
  )
}
// <Route path={`${url}/:imgNum`}>
//   <Detail mint={props.mint} updateInfo={props.updateInfo} imageLz={props.imageLz} />
// </Route>
// <Route exact path={url}>
//   {releaseInfo(true)}
//   <hr />
//   {bottom}
//   </Route>

const ControlledCarousel = (props) => {
  const [index, setIndex] = useState(0);
  // const [counter, setCounter] = useState(1);
  // const [arrLen, setArrLen] = useState(0);
  //
  // useEffect(() => {
  //
  //   // if (props.imageLz.length) {
  //   //   setCounter(Math.floor(Math.random() * props.imageLz.length))
  //   // }
  //   // console.log("len"+props.imageLz.length)
  //
  // }, []);


  // props.imageLz.length

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  // const arr = ["https://media.geeksforgeeks.org/wp-content/uploads/20201212224551/ProjectStructure.png","https://media.geeksforgeeks.org/wp-content/uploads/20210425122716/1-300x115.png"]

  return (
    <div style={{ display: 'block'}}>
    <Carousel slide={false} fade={false}>
        {props.imageLz.map((item,ind)=>{
          if (ind>props.goo){
          return (
            <Carousel.Item interval={3500} key={item.artwork_id}>
              <Link to={"/all/unforms/"+item.artwork_id}>
              <img
                className="d-block w-100"
                src={item.img}
                alt="Image"
              />
              <Carousel.Caption>
                <h3 className="drop">{item.imageTitle}</h3>

              </Carousel.Caption>
            </Link>
            </Carousel.Item>
          )
        }
        })}

        </Carousel>
        </div>
  );
}



export default CurrentShow;
