import React, { useState, useEffect } from 'react';
import { Link, Route, useRouteMatch } from "react-router-dom";
import { ProgressBar } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import {Container, Row,Col } from 'react-bootstrap';
import { Modal,Button } from 'react-bootstrap';


const NewlineText = (props) => {
  const text = props.text;
  return <p className="nu">{text}</p>;
}

// const Mo = (props) => {
//   return (
//     <Modal show={show} onHide={handleClose}>
//       <Modal.Header closeButton>
//         <Modal.Title>Modal heading</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
//       <Modal.Footer>
//         <Button variant="secondary" onClick={handleClose}>
//           Close
//         </Button>
//         <Button variant="primary" onClick={handleClose}>
//           Save Changes
//         </Button>
//       </Modal.Footer>
//     </Modal>
//
//   )
// }

const Detail = (props) => {
  // console.log("ASdfasdfasdf"+props.primaryReleaseInfo.active)

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { url } = useRouteMatch();
  const { imgNum } = useParams();

  const imgNumAdj = imgNum - 1;
  // if (imgNumAdj < 1 || imageNumAdj >  ) return (<Redirect to="/"/>)

  // console.log(imgNum)
  // if (props.imageLz[imgNum]){
  //   console.log("2222asdfasdf"+props.primaryReleaseInfo.projectName+props.imageLz[imgNum].jsonHashIpfs+props.primaryReleaseInfo.artistAddress+imgNum)
  // }
   // target='_blank' rel='noopener noreferrer'
  if (parseInt(imgNumAdj) < props.imageLz.length && parseInt(imgNumAdj) != -1 ) {
    // console.log("vvv"+imgNumAdj)
  return (
    <>
    <Modal
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="custom-modal"
      contentClassName="cusMo"
      bsClass="my-modal"
      size="lg"
      aria-labelledby="example-custom-modal-styling-title"
    >

      <Modal.Body>
        <img className="imgMod" src={`${props.imageLz[imgNumAdj].img}`}></img>
      </Modal.Body>
    </Modal>
  <Container>

    {props.imageLz.length && (
      <div>



    {true &&

      <div className="detailText">
        <div className="imgDetFloat">
          {props.imageLz[imgNumAdj] && (
          <img className="detImage" src={`${props.imageLz[imgNumAdj].img}`} alt="TopImage" onClick={handleShow} />

            )
          }
        </div>
      <hr className="half-width-detail" />
      <h2 className="curr"> {props.imageLz[imgNumAdj] && props.imageLz[imgNumAdj].imageTitle}</h2>
      <p>by {props.primaryReleaseInfo.artistName}</p>
      <hr className="half-width-detail" />
      {props.imageLz[imgNumAdj] && props.imageLz[imgNumAdj].supplementalItem.length > 0 && <React.Fragment><a href={props.imageLz[imgNumAdj].supplementalItem}>Link to Full Item</a><hr /></React.Fragment>}
      {props.primaryReleaseInfo.activeBool ?
        !props.primaryReleaseInfo.webIds.includes(parseInt(props.imageLz[imgNumAdj].artwork_id)) ?
          (
          <button
            className="buttonDark"
            type="button"
            onClick={()=>props.mint(props.primaryReleaseInfo.projectCode,props.imageLz[imgNumAdj].jsonHashIpfs,props.primaryReleaseInfo.artistAddress,props.imageLz[imgNumAdj].artwork_id,props.primaryReleaseInfo.priceInEth,props.imageLz[imgNumAdj].supplementalItem,props.imageLz[imgNumAdj].projectCode)}

          >
            Mint for {props.primaryReleaseInfo.priceInEth} Matic
          </button>
        ) :
        <button
          className="buttonDark"
          type="button"
          disabled
        >
         Already Minted
        </button>
      : (<p></p>)
    }

      <NewlineText text={props.primaryReleaseInfo.description} />



    </div>
  }


    </div>

    )
    }
    </Container>
</>

  )} else {
    return (<p> </p>)
  }
};


export default Detail;
