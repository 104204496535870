import React from "react";

import { NetworkErrorMessage } from "./NetworkErrorMessage";

export function ConnectWallet({ connectWallet, networkError, dismiss, getChain }) {
  return (
    <div >
      <div className="dog" style={{margin:"15px"}}>
        <div className="text-center">
          {networkError && (
            <>
            <NetworkErrorMessage
              message={networkError}
              dismiss={dismiss}
            />
          </>
          )}
        </div>
        <div className="text-center">


          <p>Please connect to your wallet.</p>

          <button
            className="btn btn-warning"
            type="button"
            onClick={connectWallet}
          >
            Connect Wallet
          </button>
          <br />
          <br />
          <p className="linkColor" onClick={()=>getChain(137)}>(Click here to add Polygon to your Metamask)</p>

        </div>
      </div>
    </div>
  );
}
