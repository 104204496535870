import React from "react";

export function NoWalletDetected() {
  return (
    <div className="dog" >
      <div className="text-center">
        <br />
          <p>
            No wallet was detected. <br />
            Please install{" "}
            <a
              href="https://metamask.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              MetaMask
            </a>
            .
          </p>
        </div>
      </div>

  );
}
