import React, { useState, useEffect } from 'react';
import { ImageList,ImageListItem,ImageListItemBar } from '@material-ui/core';
import { Link, Route, useRouteMatch } from "react-router-dom";
import Gallery from "./Gallery";
import { Transfer } from "./Transfer";
import Detail from "./Detail";

import {
  Router,
  Switch,
  withRouter,
  useHistory,
  NavLink,
  Redirect,
  useLocation
} from "react-router-dom";

const AllProjects = (props) => {

  // useEffect(() => {
  //   // Update the document title using the browser API
  //   // document.title = `You clicked ${count} times`;
  //   props.setBanner(props.releaseInfo.project,"by: "+props.releaseInfo.name);
  //
  // }, []);
  // var item = {pure:"moo"}

  const { url } = useRouteMatch();
  var arr = [];
  var ml = "unforms"
  // Object.keys(props.allReleasesInfo).forEach(function(key) {
  //   arr.push(props.allReleasesInfo[key]["projectCode"]);
  //   // console.log("ppp"+props.releaseInfo[key]["projectName"])
  //   // console.log(key)
  // });

  // {arr.map((item) => (
  //
  //     <Link to={`${url}/${item}`}>
  //     {<p>{item}</p>}
  //       </Link>
  //
  // ))}
  if (props.allReleasesInfo["projectCodeArr"]){
    console.log("arr"+props.allReleasesInfo["projectCodeArr"])
  }
  //

    // let bottom = (
    //   <React.Fragment>
    //     <hr />
    //     <h3 className="releaseDescription">Active Shows</h3>
    //     <hr />
    //     <div></div>
    //     <div className="row text-center">
    //     {props.allReleasesInfo["projectCodeArr"].map((item) => (
    //           <div key={item} className="col-md-4 mb-3">
    //         <Link to={`${url}/${item}`}>
    //
    //           <img className="project" src={`${ process.env.PUBLIC_URL + "/fullOut/unforms/QmaA7b7kmw8jeFz9VS9bB8vJPqm3Pgv9VbeLx3UTx7skhK.jpg"}`} />
    //           <div>{item}</div>
    //           </Link>
    //       </div>
    //     ))}
    //   </div>
    //   <hr />
    //   <h3 className="releaseDescription">Closed Shows</h3>
    //   <hr />
    //
    //   </React.Fragment>
    //
    // )

    // const bottom = (
    //   <React.Fragment>
    //   {arr &&
    //   <ImageList sx={{ width: 250, height: 250 }} cols={3} rowHeight={300}  gap={8}>
    //     {arr.map((item) => (
    //         <ImageListItem key={item.artwork_id}>
    //           <img
    //             srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format 1x,
    //                 ${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
    //             alt={item.imageTitle}
    //             loading="lazy"
    //           />
    //         <Link to={`${url}/${item.artwork_id}`}>
    //           {<ImageListItemBar position="bottom" title={<div className="galDiv"><div className="textGallery">{item.imageShortTitle}</div><div className="right">{props.primaryReleaseInfo.webIds.includes(parseInt(item.artwork_id))? "Minted":""}</div></div>} />}
    //             </Link>
    //         </ImageListItem>
    //
    //     ))}
    //   </ImageList>
    //   }
    //   </React.Fragment>
    //
    // )


    return (
      <React.Fragment>

        <Route path={`${url}/:proj`}>
          <Gallery isLoading = {props.isLoading} Loading={props.Loading} getAllMintedTokenUris={props.getAllMintedTokenUris} mint={props.mint} updateInfo={props.updateInfo} imageLz={props.imageLz} primaryReleaseInfo={props.primaryReleaseInfo} allReleasesInfo = {props.allReleasesInfo}/>
        </Route>
        <Route exact path={url}>
            {props.allReleasesInfo["projectCodeArr"] && (

                <React.Fragment>
                      <hr />
                      <h3 className="releaseDescription">Active Shows</h3>
                      <hr />
                      <div className="allproj"></div>
                      <div className="row text-center ">
                      {props.allReleasesInfo["projectCodeArr"].map((item) => (
                            <div key={item} className="col-md-4 mb-3">
                              <div className="oo">
                                <Link to={`${url}/${item}`}>

                                  <img className="imgAll"
                                    src={`${ process.env.PUBLIC_URL + "/projectIcons/"+item+".jpg"}`} />
                                  <p className="belowProj">{props.allReleasesInfo[item]["projectName"]} by {props.allReleasesInfo[item]["artistName"]}</p>
                                  </Link>
                          </div>
                        </div>
                      ))}

                    </div>
                    <hr />
                    <h3 className="releaseDescription">Closed Shows</h3>
                    <hr />
                  </React.Fragment>


          )}
        </Route>
        </React.Fragment>
        )
      }






export default AllProjects;
