import React, { useState, useEffect } from 'react';
import logo from '../scum.jpg';
import { useLocation } from "react-router-dom";
import { Link, Route, useRouteMatch } from "react-router-dom";
import { useParams } from "react-router-dom";

const Banner = (props) => {
  const path = useLocation().pathname;
  const location = path.split("/")[1];
  // console.log("path"+path)

  const { url } = useRouteMatch();
  const { p } = useParams();
  // console.log("UUUUU:"+url)

  return (
    <React.Fragment>
    <div className="row addspace" >
      <main role="main" className="col-lg-12 d-flex text-center foo">
        <div  id="verytop" className="content" style={{ backgroundImage: `url(${logo})` }}>
          <div className="center imgCont" id="toper" >
            <h1>
              {props.top}
            </h1>
            <h3>
              {props.bottom}
            </h3>
          </div>
        </div>
      </main>
  </div>

  </React.Fragment>
  )
}

export default Banner;
