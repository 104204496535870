import React, { useState, useEffect } from 'react';
import { ImageList,ImageListItem,ImageListItemBar } from '@material-ui/core';
import { Link, Route, useRouteMatch } from "react-router-dom";
import Detail from "./Detail.js"
import { useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

const NewlineText = (props) => {
  if (!props.text){
    return (<React.Fragment></React.Fragment>)
  }

  // console.log("aaa"+props.text)
  // let parasLen = props.text.split("\r\n\r\n").length;
  // console.log("lenx"+parasLen)
  // console.log(unescape(props.text))

  let un = unescape(props.text);
  var numberOfLineBreaks = (un.match(/\n/g)||[]).length;
  // console.log('Number of breaks: ' + numberOfLineBreaks);
  const regex = /^[^\r\n].*(?=\n|$)/gm;
  // const regex = /(?<=\n|^)^[^\r\n].*(?=\n|$)/gm;

  // console.log('l1 : ' + (un.match(regex)||[])[1]);

  if (numberOfLineBreaks>2){
    let firstTwo = (un.match(regex)||[])[0]+" "+(un.match(regex)||[])[1]
    return ( <div ><p className="nu">{props.showAllText ? props.text : firstTwo}<span className="ital expandColor"  onClick={() => props.setShowAllText(!props.showAllText)}>{props.showAllText ? " (Collapse Description)" : " (Expand Description)"}</span></p></div>);

  } else {
    return ( <div ><p className="nu">{props.text} </p></div>);

  }
  // firstTwo = props.text.split("\r\n\r\n")[0] + " " + props.text.split("\r\n\r\n")[1];
  // return <div>asbdfbasdf</div>
}


const useStyles = makeStyles({
  a: {
    display: 'block',
    height: '100%',
  },
  img: {
    objectFit: 'cover',
    width: '100%',
    height: '100%',
  },
});



const Gallery = (props) => {
  const classes = useStyles();

  // useEffect(() => {
  //   // Update the document title using the browser API
  //   // document.title = `You clicked ${count} times`;
  //   props.setBanner(props.releaseInfo.project,"by: "+props.releaseInfo.name);
  //
  // }, []);



  const { url } = useRouteMatch();
  const { proj } = useParams();
  // console.log("again here"+props.primaryReleaseInfo.webIds)


  const [showAllText, setShowAllText] = useState(false);



  useEffect(() => {
    console.log("before"+proj)
    if (props.primaryReleaseInfo.projectCode !== proj){
      console.log("sssss")
      props.updateInfo(proj);
    }
    // let parasLen = props.text.split("\r\n\r\n").length;
    // console.log("lenx"+parasLen)
    // // console.log(unescape(props.text))
    // let un = unescape(props.text);
    // let firstTwo = props.text;
    // var numberOfLineBreaks = (un.match(/\n/g)||[]).length;
    // console.log('Number of breaks: ' + numberOfLineBreaks);
    // // if (parasLen>2)
    // firstTwo = props.text.split("\r\n\r\n")[0] + " " + props.text.split("\r\n\r\n")[1];
    // console.log('seeee');
  }, [proj]);
    // console.log("sdfsdf"+this.props.itemData)
    // <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
    //   {this.props.itemData.map((item) => (
    //     <ImageListItem key={item.img}>
    //       <img
    //         srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format 1x,
    //             ${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
    //         alt={item.title}
    //         loading="lazy"
    //       />
    //     </ImageListItem>
    //   ))}
    // </ImageList>



    // <ImageList variant="masonry" cols={3} gap={8}>
    //   {this.props.itemData.map((item) => (
    //     <ImageListItem key={item.img}>
    //       <img
    //         srcSet={`${item.img}?w=161&fit=crop&auto=format 1x,
    //           ${item.img}?w=161&fit=crop&auto=format&dpr=2 2x`}
    //         alt={item.title}
    //         loading="lazy"
    //       />
    //     </ImageListItem>
    //   ))}
    // </ImageList>
    // console.log("cvvv"+props.releaseInfo)


    // projectName: projectDetails[0],
    // artistName: projectDetails[1],
    // description: projectDetails[2],
    // license: projectDetails[4],
    // artistAddress:projectTokenInfo[0],
    // priceInEth: parseInt(projectTokenInfo[1])/WEI_IN_ETH,
    // minted:projectTokenInfo[2],
    // maxMint:projectTokenInfo[3],
    // activeBool:projectTokenInfo[4],

    const releaseInfoTop = (showAll) =>{
      return(
        <div className="releaseDescription">

        {/*}
      <h1>{props.releaseInfo.project}</h1>
      <h4>By: {props.releaseInfo.name}</h4>
      */}

      {showAll &&
        <div className="desc">
          <h1><span className="boldIt black">{props.primaryReleaseInfo.projectName}</span></h1>
          <h4><span className="ital">by {props.primaryReleaseInfo.artistName}</span></h4>

            {props.primaryReleaseInfo.description && (<NewlineText showAllText={showAllText} setShowAllText={setShowAllText} text={props.primaryReleaseInfo.description}/>)}


          {props.primaryReleaseInfo.activeBool
            ? <React.Fragment>


            <p><span className="boldIt">Minted: </span>{props.primaryReleaseInfo.minted} out of {props.primaryReleaseInfo.maxMint} ({props.primaryReleaseInfo.maxMint-props.primaryReleaseInfo.minted} Mints Remaining)</p>
            <p><span className="boldIt">Mint Price: </span>{props.primaryReleaseInfo.priceInEth} Matic </p>
              <p><span className="boldIt">Minting Policy:<br /></span>{props.primaryReleaseInfo.mintingPolicy}</p>
          </React.Fragment>
            : <p className="ital">(Project Not Active)</p>
          }


        </div>
      }

      </div>
      )
    }

    const bottom = (
      <React.Fragment>
      {props.imageLz.length &&
      <ImageList sx={{ width: 250, height: 250 }} cols={3} rowHeight={300}  gap={8}>
        {props.imageLz.map((item) => (
            <ImageListItem key={item.artwork_id}>
              <Link className={classes.a} to={`${url}/${item.artwork_id}`}>
              <img
                className={classes.img}
                srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format 1x,
                    ${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                alt={item.imageTitle}
                loading="lazy"
              />
          </Link>
              {<ImageListItemBar position="bottom" title={<div className="galDiv"><div className="textGallery">{item.imageShortTitle}</div><div className="right">{props.primaryReleaseInfo.webIds.includes(parseInt(item.artwork_id))? "Minted":""}</div></div>} />}
            </ImageListItem>

        ))}
      </ImageList>
      }
      </React.Fragment>

    )

// QUESTIONS /TODO
//does it need to be id as int or can be hash?
//FIX the link tag down there
  // console.log("url: ",url)
    return (
      <React.Fragment>

        <Route path={`${url}/:imgNum`}>
          <Detail  isLoading = {props.isLoading} Loading={props.Loading}  primaryReleaseInfo={props.primaryReleaseInfo} mint={props.mint} updateInfo={props.updateInfo} imageLz={props.imageLz} />
        </Route>
        <Route exact path={url}>
          {releaseInfoTop(true)}
          <hr />
          {bottom}
          </Route>
      </React.Fragment>
    )
  }


export default Gallery;

// <React.Fragment>
// {props.imageLz.length &&
// <ImageList sx={{ width: 250, height: 250 }} cols={3} rowHeight={300}  gap={8}>
//   {props.imageLz.map((item) => (
//       <ImageListItem key={item.number}>
//         <img
//           srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format 1x,
//               ${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
//           alt={item.imageTitle}
//           loading="lazy"
//         />
//       <Link to={`${url}/${item.number}`}>
//         {<ImageListItemBar position="bottom" title={item.imageTitle} />}
//           </Link>
//       </ImageListItem>
//
//   ))}
// </ImageList>
// }
// </React.Fragment>


// <React.Fragment>
//   {props.imageLz.map((item) => (
//     <div key={item.number} className="col-md-4 mb-3">
//       <Link to={`${url}/${item.number}`}>
//
//         <img className="token" src={`${item.img}?w=164&h=164&fit=crop&auto=format 1x,
//             ${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
//
//         />
//       </Link>
//     </div>
//   ))}
//
// </React.Fragment>
