import React, { useState, useEffect } from 'react';
import { ImageList,ImageListItem,ImageListItemBar } from '@material-ui/core';
import { Link, Route, useRouteMatch } from "react-router-dom";
import Gallery from "./Gallery";
import { Transfer } from "./Transfer";
import Detail from "./Detail";

import {
  Router,
  Switch,
  withRouter,
  useHistory,
  NavLink,
  Redirect,
  useLocation
} from "react-router-dom";

const MyScum = (props) => {

  useEffect(() => {
    props.handleCollectionRefresh()
  }, []);

  const { url } = useRouteMatch();
  var arr = [];
  var ml = "unforms"

  if (props.myCollection){
    console.log("arr"+props.myCollection)
  }


    return (
      <React.Fragment>

        <Route exact path={url}>
            {props.myCollection && (

                <React.Fragment>
                      <hr />
                      <div className="ma">

                            <div className="b"><h3 className="collDescription">My Collection</h3></div>
                            <div className="c">  <a href="#" onClick={()=>props.handleCollectionRefresh()} style={{}} className=" btn btn-info btn-sm"><span className="glyphicon glyphicon-refresh"></span>Reload</a></div>

                      </div>

                      <hr />
                      <div className="allproj"></div>
                      <div className="row text-center ">
                      {props.myCollection.map((item) => (
                            <div key={item["imgUri"]} className="col-md-4 mb-3">
                              <div className="oo">


                                  <img className="imgAll"
                                    src={`${ item["imgUri"]}`} />
                                  <p className="belowProj">{item["title"]}<br />from {item["projectName"]}</p>

                          </div>
                        </div>
                      ))}

                    </div>

                  </React.Fragment>


          )}
        </Route>
        </React.Fragment>
        )
      }


      // <span ><span onClick={()=>props.handleCollectionRefresh()} className="releaseDescription">My Collection </span><span>(Refresh)</span><i class="fa fa-user-o" aria-hidden="true"></i></span>
//



export default MyScum;
