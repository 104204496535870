import React, { useState, useEffect } from 'react';
import { Link, Route, useRouteMatch } from "react-router-dom";
import { ProgressBar } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import {Container, Row,Col } from 'react-bootstrap';


const NewlineText = (props) => {
  if (!props.text){
    return (<React.Fragment></React.Fragment>)
  }

  // console.log("aaa"+props.text)
  // let parasLen = props.text.split("\r\n\r\n").length;
  // console.log("lenx"+parasLen)
  // console.log(unescape(props.text))

  let un = unescape(props.text);
  var numberOfLineBreaks = (un.match(/\n/g)||[]).length;
  // console.log('Number of breaks: ' + numberOfLineBreaks);
  const regex = /^[^\r\n].*(?=\n|$)/gm;

  // console.log('l1 : ' + (un.match(regex)||[])[1]);

  if (numberOfLineBreaks>1){
    let firstTwo = (un.match(regex)||[])[0]+" "+(un.match(regex)||[])[1]
    return ( <div ><p className="nu">{props.showAllText ? props.text : firstTwo}<span className="ital linkColor"  onClick={() => props.setShowAllText(!props.showAllText)}>{props.showAllText ? " (Collapse Description)" : " (Expand Description)"}</span></p></div>);

  } else {
    return ( <div ><p className="nu">{props.text} </p></div>);

  }
  // firstTwo = props.text.split("\r\n\r\n")[0] + " " + props.text.split("\r\n\r\n")[1];
  // return <div>asbdfbasdf</div>
}

const GalleryInfo = (props) => {
  // console.log("ASdfasdfasdf")
  const [showAllText, setShowAllText] = useState(false);

  const [showAllText1, setShowAllText1] = useState(false);
  const ppc ="Pixel Push Club is a Carson City based mathematician\/artist that works in code and mixed-media. Pix is a member of the NYC-based collective Pixel Pushers Union Square and is a graduate of Yale University.\r\n\r\nPix has exhibited in many venues including the RigidNice Gallery, the No Museum, Moloch Festival of Arts and Math, and EyeWash Gallery.\r\n\r\nIn 2012, Pixel Push Club self-published a book of mathematical drawings entitled \"Mathematical Drawing.\"\r\n\r\nIn 2013, Pixel Push Club was commissioned by the Urwin LeCalle to create a work for the Turn Circle Bienale. The work, entitled \"Mixed-Media Mandala,\" was a collaboration with The Institute of Figuring. Subsequently, Pixel Push Club collaborated with The Institute of Figuring to create a mixed-media installation for the exhibition \"The Great Math Mystery.\"\r\n\r\nIn 2015, Pixel Push Club created a mathematical drawing that was featured in the New York Times' The Newest Math.\r\n\r\nIn 2016, Pixel Push Club collaborated with artist Molly Crable to create a mathematical drawing for \"The Great Math Mystery\" exhibition for the Festival of New Media MathMakers."
  const hh = "Hung\/Horse is a human\/machine art collective based out of Oakland, CA composed of D. Rossell and G. Jackson along with the AIs \"Pony\" and \"Horse\"  and a rotating cast of additional members. Their work began in 2014 with a series of intricate experiments in human\/machine collaboration in the fields of digital art and performance. These mostly failed experiments lead to the birth of the collective.\r\n\r\nThey made their public debut at Gltiz.Io-ShoKaze in Spokane, WA in 2015 with the piece \"Wassily's Washerwoman's Window Washer.\" This piece consisted of two performers manipulating household items while AI processed the data and sent instructions to the performers about what to do next.\r\n\r\nSince then, Hung\/Horse has shown work in various galleries and festivals around the world. They have expanded into other digital mediums with immersive virtual reality and video games. Hung\/Horse has collaborated with various other art collectives, including working with artist Motto Lucko on the video game \"Some Days Are Not So Good'. Their debut full-length video release is entitled \"Wassily's Window Washer\". Their debut vinyl release is entitled \"Some Things I Can Say Are Not Okay.\"\r\n\r\nHung\/Horse's work has been the subject of two short documentaries, one about their earth work \"Intersoandso\" in the California Central Valley and another about their early days."
  const { url } = useRouteMatch();

  // console.log(imgNum)
  // if (props.imageLz[imgNum]){
  //   console.log("2222asdfasdf"+props.primaryReleaseInfo.projectName+props.imageLz[imgNum].jsonHashIpfs+props.primaryReleaseInfo.artistAddress+imgNum)
  // }

  return (
    <Container>
      <Row>
      <Col md="2">


      </Col>
      <Col md="8">


    <div className="infoText">
      <hr style={{margin: "10px"}}/>
      <h2 className="curr justBlack">About ScumBlocks:</h2>
      <hr style={{margin: "15px"}}/>
      <p>ScumBlocks is a gallery of very fine and very low arts sourced from the art world and the gutters of the internet. All work is rigorously curated for vigor, content, and beauty by the ScumBlocks team and presented in limited runs. <br /><br />The ScumBlocks Gallery is cross chain, with its current primary residence on Polygon. ScumBlocks was launched in August of 2021.</p>
      <p>The offical ScumBlocks address on Polygon is: 0xFA658B1eAAf17B6b2bb305b7389d198E14cAc24f</p>


      <hr className="topmar" />
      <h2 className="curr justBlack">Risk Disclaimer:</h2>
      <hr style={{margin: "10px"}}/>
      <p>Use ScumBlocks at your own risk.</p>
      <br />
      <br />


    </div>

      </Col>
      <Col md="2">


      </Col>
      </Row>



    </Container>


  );
};

export default GalleryInfo;
